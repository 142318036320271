import React, { lazy, Suspense, Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import Loader from '../Loader';
import Firebase, { withFirebase } from '../Firebase';

// const Navigation = lazy(() => import('../Navigation'));
const LandingPage = lazy(() => import('../Landing'));
// const SignUpPage = lazy(() => import('../SignUp'));
// const SignUpCompanyPage = lazy(() => import('../SignUp/company'));
const SignUpStudentPage = lazy(() => import('../SignUp/student'));
// const SignUpProfessionalPage = lazy(() => import('../SignUp/professional'));
const SignInPage = lazy(() => import('../SignIn'));
const PasswordForgetPage = lazy(() => import('../PasswordForget'));
// const HomePage = lazy(() => import('../Home'));
const AccountPage = lazy(() => import('../Account'));
const ProfilePage = lazy(() => import('../Profile'));
const ForumPage = lazy(() => import('../Forum'));
const ForumCreate = lazy(() => import('../ForumCreate'));
const ForumPostPage = lazy(() => import('../ForumPost'));
const TermsAndConditionsPage = lazy(() => import('../TermsAndConditions'));
const _404 = lazy(() => import('../_404'));

interface State {
    loginStatusUpdated: boolean
}

interface Props {
    firebase: Firebase
}

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loginStatusUpdated: false
        };

        console.log(props.firebase);
    }

    componentDidMount() {
        let authStateChecker = this.props.firebase.loginCheck(async (user: any) => {
            if (user) {
                // User is logged in
                console.log("User is logged in");
            } else {
                console.log("User not logged in");
            }

            // Now update the remote config object so that you have the correct data
            await this.props.firebase.remoteConfig.fetchAndActivate();
            
            this.setState({ loginStatusUpdated: true });
            (authStateChecker as firebase.Unsubscribe)();
        });
    }

    createAuthenticatedRoutes = (isUserLoggedIn: boolean, paths: string[], components: React.LazyExoticComponent<(props: any) => JSX.Element>[]) => {
        if (isUserLoggedIn) {
            return paths.map((val, ix) => <Route exact path={val} key={val} component={components[ix]} />);
        } else {
            return paths.map(val => <Route exact path={val} key={val} component={SignInPage} />);
        }
    }

    render() {
        // If we've verified the user's loggin status
        if (this.state.loginStatusUpdated) {

            return (
                <Router>
                    <Suspense fallback={<Loader />} >
                        {/* <Navigation /> */}

                        <Switch>
                            {/* Unathenticated routes */}
                            <Route exact path={ROUTES.LANDING} component={LandingPage} />
                            <Route exact path={ROUTES.SIGN_UP} component={SignUpStudentPage} />
                            {/* <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
                            {/* <Route exact path={ROUTES.SIGN_UP_COMPANY} component={SignUpCompanyPage} /> */}
                            {/* <Route exact path={ROUTES.SIGN_UP_STUDENT} component={SignUpStudentPage} /> */}
                            {/* <Route exact path={ROUTES.SIGN_UP_PROFESSIONAL} component={SignUpProfessionalPage} /> */}
                            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                            <Route exact path={ROUTES.FORUM} component={ForumPage} />
                            <Route exact path={ROUTES.FORUM_POST} component={ForumPostPage} />
                            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                            <Route path={ROUTES.PROFILE} component={ProfilePage} />
                            <Route path={ROUTES.TERMS_CONDITIONS} component={TermsAndConditionsPage} />

                            {this.createAuthenticatedRoutes(this.props.firebase.isUserLoggedIn, [
                                ROUTES.FORUM_CREATE,
                                ROUTES.ACCOUNT
                            ],
                                [
                                    ForumCreate,
                                    AccountPage
                                ]
                            )}

                            {/* Page not found */}
                            <Route component={_404} />
                        </Switch>
                    </Suspense>
                </Router>
            );
        } else {
            return <Loader />;
        }
    }
}

export default withFirebase(App);