import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/remote-config';
// import { NEDUser } from '../../Interfaces/NEDUser';
import { ErrorPopup } from '../../Utils/ErrorPopup';
import { IUserStudentData } from '../../constants/input-names';
import { ForumSnippetKeys, ForumPostKeys, FORUM_POSTS_COLLECTION, FORUM_SNIPPETS_COLLECTION, FORUM_LIKES_COLLECTION, ForumPostType, FORUM_POSTS_PER_PAGE, ForumLikeKeys } from '../../constants/Forum';
import { IForumPost, ForumPostBase, IForumPostDoc, IForumSnippetKeys, IForumComment, ForumCommentBase } from '../../Interfaces/Forum';
import { NEDUserTypes, USER_COMP, USER_COMP_SNIPPET, NEDStudentUser, INEDStudentUserElement, IUserComp } from '../../Interfaces/NEDUser';
import { RemoteConfig } from '../../constants/RemoteConfig';
import Swal from 'sweetalert2';

const prodConfig = {
    apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID,
    measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID
};

// const fireabaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
    static app = app;

    // Firebase services
    /** Authentication service */
    public auth: app.auth.Auth;
    /** Cloud Firestore database service */
    public firestore: app.firestore.Firestore;
    /** Analytics service */
    public analytics: app.analytics.Analytics;
    /** Remote Config service */
    public remoteConfig: app.remoteConfig.RemoteConfig

    // Used when the user login check is being done the first (and only) time
    public loginCheckDone: boolean;
    // Used to see if the user is logged in
    public isUserLoggedIn: boolean;
    // The user's database information
    private userData: IUserStudentData | any;

    constructor() {
        // Initialize Firebase
        app.initializeApp(process.env.NODE_ENV === 'production' ? prodConfig : firebaseConfig);
        this.auth = app.auth();
        this.firestore = app.firestore();
        this.analytics = app.analytics();
        this.remoteConfig = app.remoteConfig();

        this.remoteConfig.settings = {
            minimumFetchIntervalMillis: 43000000,
            fetchTimeoutMillis: 70000
        };

        this.remoteConfig.defaultConfig = ({
            fppp: FORUM_POSTS_PER_PAGE,
        });

        this.loginCheckDone = false;
        this.isUserLoggedIn = false;
    }

    async getUserInfo(uid: string): Promise<IUserStudentData | undefined> {
        let data = await this.firestore.collection('users').doc(uid).get().then(doc => doc.data() as any);
        console.log('Data:', data);
        if (data) {
            data.uid = uid;
            data = this.parseUserData(data);
            console.log("A user's profile:", data);
            return data;
        }
    }

    public loginCheck(callback: Function) {
        if (!this.loginCheckDone) {
            return this.auth.onAuthStateChanged(async (user) => {
                // TODO: If user's a guest, don't take as a user
                if (user) {
                    this.isUserLoggedIn = true;
                    this.userData = await this.getUserInfo(user.uid) as IUserStudentData;
                } else {
                    this.isUserLoggedIn = false;
                }

                this.loginCheckDone = true;

                callback(user);
            });
        }
    }

    private parseUserData(data: IUserStudentData): IUserStudentData {
        // let newData = {};
        // for (let key in data) {
        //     newData[Forum]
        // }
        // TODO: Make sure the user data is obfuscated and that we parse it here
        return data;
    }

    public signUpWithEmailAndPassword = (email: string, password: string) => this.auth.createUserWithEmailAndPassword(email, password);

    public signInWithEmailAndPassword = (email: string, password: string) => this.auth.signInWithEmailAndPassword(email, password).catch();

    public signOutUser = () => this.auth.signOut();

    public passwordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

    public passwordUpdate = (password: string) => this.auth.currentUser?.updatePassword(password);

    public getUserData = (): IUserStudentData => {
        return this.userData;
    };

    private getProfileProps(): NEDStudentUser {
        let propertyElements: NEDStudentUser = {} as NEDStudentUser;

        // TODO: Change this code to use a segmented system where we get the input:texts and textareas first, then the radio buttons, and finally followed by select element values

        document.querySelectorAll('form [name="gender"]:checked, form [name]:not([type=radio])').forEach((ele: any) => {
            if (ele.dataset.fieldType === 'array') {
                propertyElements[(ele as INEDStudentUserElement).name] = ele.value.split(/, */i).filter((val: any) => val);
            } else if (ele.dataset.fieldType === 'bool') {
                propertyElements[(ele as INEDStudentUserElement).name] = window.parseFloat(ele.value);
            } else {
                propertyElements[(ele as INEDStudentUserElement).name] = ele.value;
            }
        });

        return propertyElements;
    }

    public updateUserData = async (event: React.FormEvent<HTMLFormElement>, accountType: NEDUserTypes): Promise<boolean> => {
        event.preventDefault();
        // alert('All is good');
        // console.log('The form event', event);
        // Create a package that we'll use to create the user's data
        // TODO: create an interface/type for the packet (i.e. NEDUser)
        // let packet: NEDUser;
        // Now fill it up with the values we'll need
        // 

        let propertyElements: NEDStudentUser = this.getProfileProps() as NEDStudentUser;

        // Make sure the date is a number
        propertyElements.birthday = (new Date(propertyElements.birthday)).getTime();
        propertyElements.type = accountType;

        return await this.sendUserDataToServer(propertyElements, this.auth.currentUser, true).then(() => {
            // console.log("Everything went well");
            return true;
        }).catch(() => {
            ErrorPopup("Account Error");
            return false;
        });
    }

    public doSignUpAndCreateUserData = async (event: React.FormEvent<HTMLFormElement>, accountType: NEDUserTypes): Promise<boolean> => {
        event.preventDefault();
        // alert('All is good');
        // console.log('The form event', event);

        // Create a package that we'll use to create the user's data
        // TODO: create an interface/type for the packet (i.e. NEDUser)
        // let packet: NEDUser;

        // Now fill it up with the values we'll need
        // 

        // TODO: create an interface/type for the propertyElements
        let propertyElements: NEDStudentUser = this.getProfileProps();

        // Make sure the date is a number
        propertyElements.birthday = (new Date(propertyElements.birthday)).getTime();
        propertyElements.type = accountType;
        let password = document.getElementById('password') as HTMLInputElement;
        // Get the profile image for 
        // First signup and then create user in database
        let finished = await this.signUpWithEmailAndPassword(propertyElements.email, password?.value).then((userCredentials: app.auth.UserCredential) => {
            // Now send data to the server
            return this.sendUserDataToServer(propertyElements, userCredentials.user, false);
        }).then(() => {
            // console.log("Everything went well");
            return true;
        }).catch(() => {
            ErrorPopup("Signup Error");
            return false;
        });
        console.log('Finished?', finished);
        return !!finished;
    };

    public sendUserDataToServer = (packet: NEDStudentUser, user: app.User | null, isUpdate: boolean): Promise<void[]> => {
        if (user) {
            if (isUpdate) {
                return Promise.all([
                    this.firestore.collection('users').doc(user?.uid).update(packet),
                    this.firestore.collection('users').doc(user?.uid).collection(USER_COMP).doc(USER_COMP_SNIPPET).update({
                        'n': `${packet.firstname} ${packet.lastname}`,
                        't': packet.type,
                        'p': packet.career
                    })
                ]);
            }
            return Promise.all([
                this.firestore.collection('users').doc(user?.uid).set(packet),
                this.firestore.collection('users').doc(user?.uid).collection(USER_COMP).doc(USER_COMP_SNIPPET).set({
                    'n': `${packet.firstname} ${packet.lastname}`,
                    't': packet.type,
                    'p': packet.career
                })
            ]);
        } else {
            return Promise.reject();
        }
    };

    public async updateUserProp(props: NEDStudentUser | any): Promise<void> {
        // 
        const user = this.auth.currentUser;
        if (!!user) {
            // We update the user's properties
            return this.firestore.collection('users').doc(user.uid).update(props);
        }
    }

    public getUserCompData = (uid: string): Promise<IUserComp> => {
        return this.firestore.collection('users').doc(uid).collection(USER_COMP).doc(USER_COMP_SNIPPET).get().then(doc => {
            return { ...doc.data(), id: uid } as IUserComp;
        });
    }

    public createForumPost = async (snippet: IForumSnippetKeys, fullArticle: { [key in (ForumPostKeys)]?: any }): Promise<string> => {
        // Get the user's document
        const userDoc = this.firestore.collection('users').doc(this.auth.currentUser?.uid);

        // Create the post in the /forum_snippets sub-collection
        const forumPostRef = userDoc.collection(FORUM_SNIPPETS_COLLECTION).doc();

        // Get the id of the document
        const forumPostID = forumPostRef.id;

        snippet[ForumSnippetKeys.ID] = forumPostID;
        fullArticle[ForumPostKeys.ID] = forumPostID;

        // Await for the snippet and the full post to be added
        await forumPostRef.set(snippet);
        return await userDoc.collection(FORUM_POSTS_COLLECTION).doc(forumPostID).set(fullArticle).then(() => forumPostID);
    };

    public createForumComment = async (comment: { [key in (ForumPostKeys)]?: any }): Promise<void> => {
        // Get the user's document
        const userDoc = this.firestore.collection('users').doc(this.auth.currentUser?.uid);

        // Create the post in the /forum_snippets sub-collection
        const forumCommentRef = userDoc.collection(FORUM_POSTS_COLLECTION).doc();

        // Get the id of the document
        const forumCommentID = forumCommentRef.id;

        // comment[ForumPostKeys.AUTHOR] = this.auth.currentUser?.uid;
        comment[ForumPostKeys.ID] = forumCommentID;

        // Await for the snippet and the full post to be added
        await userDoc.collection(FORUM_POSTS_COLLECTION).doc(forumCommentID).set(comment).then(() => forumCommentID);
    };

    public incrementProp = async (doc: string, prop: string, increment = 1) => {
        const docRef = this.firestore.doc(doc);

        let transactionSucceeded = true;

        await this.firestore.runTransaction(transaction => {
            return transaction.get(docRef).then(serverDoc => {
                if (!serverDoc.exists) {
                    throw new Error('Document does not exist');
                }

                const data = serverDoc.data();

                if (typeof (data as any)[prop] === 'number') {
                    console.log('Yep that is correct');
                    const newCount = Math.max(0, (data as any)[prop] + increment);

                    console.log('New count:', newCount);

                    transaction.update(docRef, { [prop]: newCount });
                } else {
                    throw new Error(`The property ${prop} does not exist in the document ${doc}`);
                }
            });
        }).catch(error => {
            Swal.fire({
                title: 'Failed to Increment prop',
                icon: 'error',
                text: error
            });
            transactionSucceeded = false;
        });

        return transactionSucceeded;
    };

    public likePost = async (authorID: string, postID: string, likeProp: string): Promise<number> => {
        if (this.isUserLoggedIn) {
            const LIKE_DOC = `users/${this.auth.currentUser?.uid}/${FORUM_LIKES_COLLECTION}/${postID}`;
            const POST_DOC = `users/${authorID}/${FORUM_SNIPPETS_COLLECTION}/${postID}`;

            console.log('Post Doc:', POST_DOC);

            // First check if the user has liked this post already
            const { postLiked, liked_post } = await this.firestore.doc(LIKE_DOC).get().then(doc => {
                return {
                    postLiked: doc.exists,
                    liked_post: doc.ref
                };
            });

            let promiseList: Promise<boolean>[] = [];
            let increment = 0;

            // If the post is liked
            if (postLiked) {
                // unlike the post

                increment = -1;
                promiseList = [
                    liked_post.delete().then(() => true).catch(() => false),
                    this.incrementProp(POST_DOC, ForumSnippetKeys.LIKE_COUNT, increment)
                ];
            } else {
                // like the post

                increment = 1;
                promiseList = [
                    liked_post.set({ [ForumLikeKeys.TIMESTAMP]: (new Date()).getTime() }).then(() => true).catch(() => false),
                    this.incrementProp(POST_DOC, ForumSnippetKeys.LIKE_COUNT, increment)
                ];
            }

            return await Promise.all(promiseList).then(() => increment);
        } else {
            return await Promise.resolve(0);
        }
    };

    public deletePost = async (post_id: string): Promise<boolean> => {
        // Get the user's ID
        const { uid } = this.getUserData();

        // Create a batch
        const batch = this.firestore.batch();
        
        // Get refs to the user's post in the forum_snippets and forum_posts collections
        const userRef = this.firestore.collection('users').doc(uid);
        const snippetRef = userRef.collection(FORUM_SNIPPETS_COLLECTION).doc(post_id);
        const postRef = userRef.collection(FORUM_POSTS_COLLECTION).doc(post_id);
        
        // postRef.get().then(doc => {
        //     console.log('Data:', doc.data());
        // });
        // Update the snippets and the posts
        batch.delete(snippetRef);
        batch.delete(postRef);

        return await batch.commit().then(() => true).catch(() => false);
        // return false;
    }

    public getUncachedUserCompsFromPosts = (posts: IForumPost[], userComps: IUserComp[]) => {
        return posts.filter(post => !userComps.find(userComp => userComp.id === post[ForumSnippetKeys.AUTHOR]))
            // Now remap the returned array so that it holds only the user UIDs that we need
            .map(post => post[ForumSnippetKeys.AUTHOR]);
    }

    public getUncachedUserCompsFromComments = (posts: IForumComment[], userComps: IUserComp[]) => {
        return posts.filter(post => !userComps.find(userComp => userComp.id === post[ForumPostKeys.AUTHOR]))
            // Now remap the returned array so that it holds only the user UIDs that we need
            .map(post => post[ForumPostKeys.AUTHOR]);
    }

    public getForumPostContent = async (post_id: string, userID: string): Promise<{ c: string; rp: string; }> => {
        return await this.firestore.collection('users').doc(userID).collection(FORUM_POSTS_COLLECTION).doc(post_id).get().then(doc => doc.data() as { c: string; rp: string; });
    };

    public getForumPosts = async (offset: IForumPostDoc, parentID?: string): Promise<IForumPost[]> => {
        // We'll store our posts here
        let posts: any[] = [];

        let fs = this.firestore;

        // Create the firestore query pointing to the snippets collection
        let query = fs.collectionGroup(FORUM_SNIPPETS_COLLECTION);

        if (!!offset) {
            // Offset is not a number and instead a doc
            query = query.where(ForumSnippetKeys.TYPE, '==', ForumPostType.POST).orderBy(ForumSnippetKeys.UPDATED_ON_TIMESTAMP, 'desc').startAfter(offset).limit(this.remoteConfig.getNumber(RemoteConfig.FORUM_POSTS_PER_PAGE));
        } else {
            // This is our first post
            query = query.where(ForumSnippetKeys.TYPE, '==', ForumPostType.POST).orderBy(ForumSnippetKeys.UPDATED_ON_TIMESTAMP, 'desc').limit(this.remoteConfig.getNumber(RemoteConfig.FORUM_POSTS_PER_PAGE));
        }

        // Now get the posts
        posts = await query.get().then(this.processPostData);

        return posts;
    }

    private processPostData = (qs: app.firestore.QuerySnapshot<app.firestore.DocumentData>): IForumPost[] => {
        // Btw, qs stands for querySnapshot

        // We'll store our posts here
        let posts: IForumPost[] = [];

        qs.forEach(doc => {
            posts.push({ ...(doc.data() as ForumPostBase), post_id: doc.id, doc, comp: null });
        });

        return posts;
    };

    public getUserForumPosts = async (uid: string): Promise<IForumPost[]> => {
        const posts: IForumPost[] = await this.firestore.collectionGroup(FORUM_SNIPPETS_COLLECTION)
            .where(ForumSnippetKeys.TYPE, '==', ForumPostType.POST)
            .where(ForumSnippetKeys.AUTHOR, '==', uid)
            .get()
            .then(this.processPostData);

        return posts;
    };

    public getPostComments = async (offset: IForumPostDoc, parentID?: string): Promise<IForumComment[]> => {
        // We'll store our posts here
        let posts: any[] = [];

        let fs = this.firestore;

        console.log('GETPOSTCOMMENTS Arg', offset, parentID);

        // Create the firestore query pointing to the snippets collection
        let query = fs.collectionGroup(FORUM_POSTS_COLLECTION)
            .where(ForumPostKeys.RELATED_POST, '==', parentID)
            .orderBy(ForumPostKeys.UPDATED_ON_TIMESTAMP, 'desc');

        if (!!offset) {
            // Offset is not a number and instead a doc
            query = query.startAfter(offset);
        }

        query = query.limit(this.remoteConfig.getNumber(RemoteConfig.FORUM_POSTS_PER_PAGE));

        // Now get the posts
        posts = await query.get().then(qs => {
            // Btw, qs stands for querySnapshot

            // We'll store our posts here
            let posts: IForumComment[] = [];

            qs.forEach(doc => {
                posts.push({ ...(doc.data() as ForumCommentBase), post_id: doc.id, doc, comp: null });
            });

            return posts;
        });

        console.log('Post Comments', posts);

        return posts;
    }

    public removeDuplicates<T>(currentPosts: T[], newPosts: T[]): T[] {
        const newCurrentPosts: T[] = [];

        newPosts.forEach((post: T | any) => {
            if (!currentPosts.find((_post: T | any) => _post.post_id === post.post_id)) {
                // 
                newCurrentPosts.push(post);
            }
        });

        return newCurrentPosts;
    }
}

export default Firebase;