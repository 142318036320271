import React from 'react';
import Firebase from './firebase';
import { RouteComponentProps } from 'react-router-dom';

const FirebaseContext: React.Context<any | Firebase> = React.createContext(null);

export const withFirebase = (Component: any) => (props: (RouteComponentProps | any)) => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;