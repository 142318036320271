export enum ForumSnippetKeys {
   TYPE = 't',
   AUTHOR = 'a',
   AUTHOR_TYPE = 'at',
   TAGS = 'tg',
   TIMESTAMP = 'ts',
   UPDATED_ON_TIMESTAMP = 'uts',
   TITLE = 'ti',
   ID = 'i',
   COMMENT_COUNT = 'cc',
   LIKE_COUNT = 'lc',
}

export enum ForumPostKeys {
   CONTENT = 'c',
   RELATED_POST = 'rp',
   AUTHOR = 'a',
   ID = 'i',
   UPDATED_ON_TIMESTAMP = 'uts',
   TIMESTAMP = 'ts',
}

export enum ForumPostType {
   POST,
   REPLY
}

export enum ForumLikeKeys {
   TIMESTAMP
}

export const FORUM_POSTS_COLLECTION = 'forum_posts';
export const FORUM_SNIPPETS_COLLECTION = 'forum_snippets';
export const FORUM_LIKES_COLLECTION = 'likes';

export const FORUM_POSTS_PER_PAGE = 7;

export const FIST_BUMP_SVG = `<div class="fist-animate" style="display: flex; justify-content: center;">
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   version="1.1"
   viewBox="0 0 9.9696722 7.980412"
   height="7.980412mm"
   width="9.9696722mm"
   class="svg-fist">
  <defs
     id="defs2">
    <style
       id="style3713">.cls-1{fill:#45413c;opacity:0.15;}.cls-2{fill:#ffffff;}.cls-3{fill:#ebcb00;}.cls-4{fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round;}.cls-5{fill:#fff48c;}</style>
  </defs>
  <g
     transform="translate(-100.09254,-127.21999)"
     id="layer1">
    <g
       transform="matrix(0.26458333,0,0,0.26458333,98.928537,124.74936)"
       id="g3746">
      <path
         style="fill:#ffffff"
         id="_Path_"
         data-name="&lt;Path&gt;"
         class="cls-2"
         d="m 32.44,20.92 a 3.77,3.77 0 0 0 -3.77,3.77 v 7 H 25 a 5.74,5.74 0 0 0 -5.74,5.74 v 0 A 1.59,1.59 0 0 0 20.8,39 h 11.78 a 9,9 0 0 0 9,-8.95 v 0 a 9.1,9.1 0 0 0 -9.14,-9.13 z" />
      <path
         style="fill:#ebcb00"
         class="cls-3"
         d="m 41.53,29.64 a 9.1,9.1 0 0 0 -9.09,-8.72 3.77,3.77 0 0 0 -3.77,3.77 v 7 H 25 a 5.72,5.72 0 0 0 -4.49,2.16 3.64,3.64 0 0 0 3.49,2.82 h 9.9 a 7.66,7.66 0 0 0 7.63,-7.03 z"
         id="path3721" />
      <path
         style="fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round"
         id="_Path_2"
         data-name="&lt;Path&gt;"
         class="cls-4"
         d="m 32.44,20.92 a 3.77,3.77 0 0 0 -3.77,3.77 v 7 H 25 a 5.74,5.74 0 0 0 -5.74,5.74 v 0 A 1.59,1.59 0 0 0 20.8,39 h 11.78 a 9,9 0 0 0 9,-8.95 v 0 a 9.1,9.1 0 0 0 -9.14,-9.13 z" />
      <path
         style="fill:#ffffff"
         id="_Path_3"
         data-name="&lt;Path&gt;"
         class="cls-2"
         d="m 39.14,15.68 a 4.06,4.06 0 0 0 -3.88,-4.12 5.13,5.13 0 0 0 -3.77,1.65 1,1 0 0 1 -1.55,-0.32 5,5 0 0 0 -9.21,0 0.91,0.91 0 0 1 -1.43,0.38 4.9,4.9 0 0 0 -4.83,-0.85 4.29,4.29 0 0 0 -3.11,4.42 0.77,0.77 0 0 1 -1.23,0.66 3.24,3.24 0 0 0 -2,-0.7 3.42,3.42 0 0 0 -3.23,3.43 v 11 a 3.37,3.37 0 0 0 3.07,3.42 3.24,3.24 0 0 0 3.42,-3.24 v -0.84 a 4.52,4.52 0 0 0 5.46,4.43 4.58,4.58 0 0 0 3.53,-4.52 v 0 a 5,5 0 0 0 10,0 v 0.09 a 4.52,4.52 0 0 0 4,4.55 4.38,4.38 0 0 0 4.81,-4.36 z" />
      <path
         style="fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round"
         id="_Path_5"
         data-name="&lt;Path&gt;"
         class="cls-4"
         d="m 39.14,15.68 a 4.06,4.06 0 0 0 -3.88,-4.12 5.13,5.13 0 0 0 -3.77,1.65 1,1 0 0 1 -1.55,-0.32 5,5 0 0 0 -9.21,0 0.91,0.91 0 0 1 -1.43,0.38 4.9,4.9 0 0 0 -4.83,-0.85 4.29,4.29 0 0 0 -3.11,4.42 0.77,0.77 0 0 1 -1.23,0.66 3.24,3.24 0 0 0 -2,-0.7 3.42,3.42 0 0 0 -3.23,3.43 v 11 a 3.37,3.37 0 0 0 3.07,3.42 3.24,3.24 0 0 0 3.42,-3.24 v -0.84 a 4.52,4.52 0 0 0 5.46,4.43 4.58,4.58 0 0 0 3.53,-4.52 v 0 a 5,5 0 0 0 10,0 v 0.09 a 4.52,4.52 0 0 0 4,4.55 4.38,4.38 0 0 0 4.81,-4.36 z" />
      <line
         style="fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round"
         id="_Path_6"
         data-name="&lt;Path&gt;"
         class="cls-4"
         x1="11.36"
         y1="30.610001"
         x2="11.36"
         y2="26.16" />
      <line
         style="fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round"
         id="_Path_7"
         data-name="&lt;Path&gt;"
         class="cls-4"
         x1="20.379999"
         y1="30.459999"
         x2="20.379999"
         y2="24.790001" />
      <line
         style="fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round"
         id="_Path_8"
         data-name="&lt;Path&gt;"
         class="cls-4"
         x1="30.379999"
         y1="30.459999"
         x2="30.379999"
         y2="22.629999" />
    </g>
  </g>
</svg>
</div>
`;