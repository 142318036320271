import Swal, { SweetAlertOptions } from "sweetalert2";

export const ErrorPopup = function (title: string = "Error!", customCheck?: Function) {
    // Returns a method that's passed to the catch method of the promise, so that when an error occurs, the popup is shown properly
    return (error: firebase.auth.Error) => {
        let customCheckResult: undefined | SweetAlertOptions<unknown, any> = {};

        if (!!customCheck) {
            customCheckResult = customCheck(error);
        }

        // Show popup with error message
        Swal.fire({
            title,
            text: error.message,
            icon: 'error',
            ...customCheckResult
        });
    }
}