export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_UP_COMPANY = '/signup/company';
export const SIGN_UP_PROFESSIONAL = '/signup/professional';
export const SIGN_UP_STUDENT = '/signup/student';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const PROFILE = '/profile/:uid';
export const TERMS_CONDITIONS = '/terms-conditions';
export const FORUM = '/forum';
export const FORUM_CREATE = '/forum/new';
export const FORUM_POST = '/forum/post/:postID';