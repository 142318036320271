import { StudentInputNames } from "../constants/input-names";

type _BaseNEDStudentUser = {
    [key in StudentInputNames]: any;
};

export interface NEDStudentUser extends _BaseNEDStudentUser {
    /** Flag that holds information on whether the tutorial/onboarding is done */
    tutDone?: boolean;
}

export interface INEDStudentUserElement extends Element {
    name: StudentInputNames
}

export enum NEDUserTypes {
    STUDENT,
    PROFESSIONAL,
    COMPANY
}

export interface IUserComp {
    n: string;
    t: NEDUserTypes;
    p: string;
    id: string;
}

export enum UserCompKeys {
    NAME = 'n',
    TYPE = 't',
    PROFESSION = 'p'
}

export const USER_COMP = 'comp';
export const USER_COMP_SNIPPET = 'user_snippet';